<template>
  <Page>
    <template #title>
      Checkouts
    </template>

    <Table
      date-key="createdAt"
      :items="checkouts"
      :state="state"
      :total-item-count="checkoutCount"
      :items-per-page="pageSize"
      item-type="Checkout"
      :count="{ singular: 'checkout', plural: 'checkouts' }"
      :headers="tableHeaders"
      hideHeader
      @next="goToNextPage"
      @prev="goToPreviousPage"
    >
      <template #tableHeader>
        <div class="flex justify-between w-full">
          <div class="flex flex-wrap items-end w-full gap-2">
            <div class="w-full max-w-xs">
              <TbInput
                v-model="filter.search"
                label="Search"
                placeholder="Name"
              />
            </div>

            <TbSelect
              v-model="filter.status"
              :default-options="{ name: 'All', value: 'all' }"
              :options="statusOptions"
              label="Status"
              class="w-[150px]"
            />

            <Drawer to="#app">
              <template #default="{ toggleDrawer }">
                <div class="flex flex-col h-full">
                  <CreateCheckout
                    @cancel="toggleDrawer();"
                    @submit="toggleDrawer(); loadCheckouts()"
                  />
                </div>
              </template>

              <template #toggle="{ toggleDrawer }">
                <RoleControlledAction
                  v-slot="{ wrapper, restricted }"
                  :user-role="roleName"
                  :config-object="roleConfig.createCheckouts"
                >
                  <button
                    :class="{ 'custom-disabled': restricted}"
                    class="self-end button button--primary button--sm"
                    @click="wrapper(()=> toggleDrawer())"
                  >
                    <span class="flex items-center gap-2">
                      Create Checkout
                      <TbPlusIcon class="w-4 h-4 text-white" />
                    </span>
                  </button>
                </RoleControlledAction>
              </template>
            </Drawer>
          </div>
        </div>
      </template>

      <template #action="{ toggleDropdown, item }">
        <div class="flex flex-col h-full gap-1">
          <!-- update -->
          <Drawer to="#app">
            <template #default="{ toggleDrawer }">
              <div class="flex flex-col h-full">
                <UpdateCheckout
                  :checkout="item"
                  @cancel="toggleDrawer()"
                  @submit="toggleDrawer(); loadCheckouts()"
                />
              </div>
            </template>

            <template #toggle="{ toggleDrawer }">
              <RoleControlledAction
                v-slot="{ wrapper, restricted }"
                :user-role="roleName"
                :config-object="roleConfig.updateCheckouts"
              >
                <button
                  :class="{ 'custom-disabled': restricted}"
                  class="button button--block button--sm text-left"
                  @click="wrapper(() => toggleDrawer())"
                >
                  Update
                </button>
              </RoleControlledAction>
            </template>
          </Drawer>

          <button
            class="button button--block button--sm text-left flex gap-2 items-center"
            @click="copyLink(item.staticIdentifier, item.name); toggleDropdown();"
          >
            <TbCopyIcon class="w-4 h-4" />
            Copy Checkout Link
          </button>

          <!-- Delete -->
          <DeleteCheckout
            :checkout="item"
            @archive="toggleDropdown(); loadCheckouts()"
            @cancel="toggleDropdown()"
          >
            <template #action="{ deleteHandler }">
              <RoleControlledAction
                v-slot="{ wrapper, restricted }"
                :user-role="roleName"
                :config-object="roleConfig.deleteCheckouts"
              >
                <button
                  :class="{ 'custom-disabled': restricted}"
                  class="button button--block text-error-900 button--sm text-left"
                  @click="wrapper(() => deleteHandler());"
                >
                  Delete
                </button>
              </RoleControlledAction>
            </template>
          </Deletecheckout>
        </div>
      </template>

      <template #row(status)="{ item }">
        <div>
          <span
            class="inline-block w-2 h-2 mr-1 rounded-full"
            :class="productStatusToColorMap[resolvedStatus(item.status, item.needsAttention).type]"
          />
          <span>
            {{ resolvedStatus(item.status, item.needsAttention).text }}
          </span>
        </div>
      </template>

      <!-- custom drawer if products are hidden -->
      <template #row(products)="{ item }">
        <div class="flex flex-row gap-2 items-center">
          <div
            v-for="row, key in getFirstTwoItems(item.products)"
            :key="key"
            class="px-2 py-[2px] rounded-md max-w-xs truncate border border-primary-200"
          >
            {{ row.name }}
          </div>
          <div
            v-if="remainingCount(item.products)"
            class="flex items-center justify-center"
          >
            <Drawer to="#app">
              <template #default="{ toggleDrawer }">
                <div class="flex flex-col h-full">
                  <UpdateCheckout
                    :checkout="item as any"
                    @cancel="toggleDrawer()"
                    @submit="toggleDrawer(); loadCheckouts()"
                  />
                </div>
              </template>

              <template #toggle="{ toggleDrawer }">
                <RoleControlledAction
                  v-slot="{ wrapper, restricted }"
                  :user-role="roleName"
                  :config-object="roleConfig.updateCheckouts"
                >
                  <a
                    :class="{ 'custom-disabled': restricted}"
                    class="cursor-pointer text-info-800 font-medium"
                    @click="wrapper(() => toggleDrawer())"
                  >
                    + {{ remainingCount(item.products) }}
                  </a>
                </RoleControlledAction>
              </template>
            </Drawer>
          </div>
        </div>
      </template>

      <template #row(totalInCents)="{ item }">
        <span
          class="currency"
        >{{ `${item.currencyCode || ''} ${currencyInCents(item.totalInCents)}` }}</span>
      </template>
    </Table>

    <TbNotification
      :show="copyNotification"
      title="Success 🎉"
      :message="copyNotificationText"
      card-class="text-white bg-success"
      @close="copyNotification = false"
    />
    <QuickStartGuideLink />
  </Page>
</template>

<script setup lang="ts">
import {
  ref, Ref, reactive, watch, onActivated, inject,
} from 'vue';
import { useRoute } from 'vue-router';
import { debounce } from 'lodash';
import {
  TbInput, TbPlusIcon, TbSelect, TbCopyIcon, TbNotification,
} from '@/components/tasty_bistro';
import { currencyInCents } from '@/filters/currency';
import Table from '@/components/table.vue';
import { PageState } from '@/types';
import Drawer from '@/components/drawer.vue';
import RoleControlledAction from '@/components/role_controlled_action.vue';
import { productStatusToColorMap } from '@/helpers/category_color_mapper';
import { formatDate } from '@/filters/date';
import { AppState } from '@/pages/app/api/get_app_state';
import QuickStartGuideLink from '@/components/quick_start_guide_link.vue';
import Page from '../../components/page.vue';
import CreateCheckout from './components/create.vue';
import UpdateCheckout from './components/update.vue';
import DeleteCheckout from './components/delete.vue';
import { getCheckouts } from './api/get_checkouts';
import { roleConfig } from './role_configurations';

const resolvedStatus = (status: string, needsAttention: boolean) => {
  if (status === 'inactive') {
    return {
      type: 'inactive',
      text: 'Inactive',
    };
  }

  if (needsAttention) {
    return {
      type: 'needs_attention',
      text: 'Needs Attention',
    };
  }

  return {
    type: 'active',
    text: 'Active',
  };
};

const route = useRoute();

const appState = inject<Ref<AppState>>('state') as Ref<AppState>;
const projectId = route.params.projectId as string;
const roleName = appState.value.projects[projectId].currentUserRoleName;

const checkouts: Ref<any[]> = ref([]);
const state = ref(PageState.loaded);
const filter = reactive({ search: '', status: 'all' });
const pageSize = 20;
const currentPage = ref(0);
const checkoutCount = ref<number>(0);
const tableHeaders = [
  {
    title: 'Name',
    key: 'name',
  },
  {
    title: 'Total',
    key: 'totalInCents',
  },
  {
    title: 'Status',
    key: 'status',
  },
  {
    title: 'Products',
    key: 'products',
  },
  {
    title: 'Last updated',
    key: 'createdAt',
    formatter: (val: string) => formatDate(val, 'MMM dd, yyyy'),
  },
];

const copyNotification = ref(false);
const copyNotificationText = ref('');

const statusOptions = [
  { name: 'Active', value: 'active' },
  { name: 'Inactive', value: 'inactive' },
];

const loadCheckouts = async () => {
  try {
    state.value = PageState.loading;
    const productsPaginated = await getCheckouts({
      projectId,
      filter: {
        name: filter.search,
        status: filter.status === 'all' ? undefined : filter.status,
        currentPage: currentPage.value,
        pageSize,
      },
    });
    checkouts.value = productsPaginated.data;
    checkoutCount.value = productsPaginated.count;
    state.value = PageState.loaded;
  } catch (error) {
    state.value = PageState.error;
  }
};

const getFirstTwoItems = (items: any[]) => items.slice(0, 2);
const remainingCount = (items: any[]) => {
  const total = items.length;

  if (total > 2) {
    return total - 2;
  }

  return 0;
};

const goToNextPage = async (page: number) => {
  currentPage.value = page;
  await loadCheckouts();
};

const goToPreviousPage = async (page: number) => {
  currentPage.value = page;
  await loadCheckouts();
};

onActivated(async () => {
  await loadCheckouts();
});

watch(() => [filter.status], async () => {
  currentPage.value = 0;
  await loadCheckouts();
});

watch(() => [filter.search], debounce(async () => {
  currentPage.value = 0;
  await loadCheckouts();
}, 500));

const copyLink = (checkoutStaticId: string, checkoutName: string) => {
  const { projectId } = route.params;
  // const v2Enabled = appState.value.projects[projectId as any].features.checkoutV2Enabled;

  copyNotification.value = false;

  setTimeout(() => {
    copyNotificationText.value = `Checkout URL for ${checkoutName} has been successfully copied to your Clipboard`;
    copyNotification.value = true;

    setTimeout(() => {
      copyNotification.value = false;
    }, 2000);
  }, 200);

  const baseUrl = import.meta.env.VITE_APP_BASE_URL;
  const link = `${baseUrl}/student/checkout/${projectId}?productId=${checkoutStaticId}`;
  navigator.clipboard.writeText(link);
};
</script>

<style lang="scss" scoped>
  .custom-disabled {
    @apply cursor-not-allowed opacity-[0.35];
  }
</style>
