<template>
  <Page>
    <template #title>
      Products
    </template>

    <Table
      date-key="createdAt"
      :items="products"
      :state="state"
      :total-item-count="productCount"
      :items-per-page="pageSize"
      item-type="Product"
      :count="{ singular: 'product', plural: 'products' }"
      :headers="tableHeaders"
      hideHeader
      @next="goToNextPage"
      @prev="goToPreviousPage"
    >
      <template #tableHeader>
        <div class="flex justify-between w-full">
          <div class="flex flex-wrap items-end w-full gap-2">
            <div class="w-full max-w-xs">
              <TbInput
                v-model="filter.search"
                label="Search"
                placeholder="Name"
              />
            </div>

            <TbSelect
              v-model="filter.state"
              :default-options="{ name: 'All', value: 'all' }"
              :options="stateOptions"
              label="Status"
              class="w-[150px]"
            />

            <TbDrawer>
              <template #default="{ toggleDrawer }">
                <div class="flex flex-col h-full">
                  <Createproduct
                    @cancel="toggleDrawer()"
                    @submit="toggleDrawer(); loadProducts()"
                  />
                </div>
              </template>

              <template #toggle="{ toggleDrawer }">
                <RoleControlledAction
                  v-slot="{ wrapper, restricted}"
                  :user-role="roleName"
                  :config-object="roleConfig.createProducts"
                >
                  <button
                    :class="{ 'custom-disabled': restricted}"
                    class="self-end button button--primary button--sm"
                    @click="wrapper(() => toggleDrawer())"
                  >
                    <span class="flex items-center gap-2">
                      Create Product
                      <TbPlusIcon class="w-4 h-4 text-white" />
                    </span>
                  </button>
                </RoleControlledAction>
              </template>
            </TbDrawer>
          </div>
        </div>
      </template>

      <template #action="{ item, toggleDropdown }">
        <div class="flex flex-col h-full gap-1">
          <!-- update -->
          <TbDrawer>
            <template #default="{ toggleDrawer }">
              <div class="overflow-hidden whitespace-normal">
                <UpdateProduct
                  :product="item"
                  @cancel="toggleDrawer(); toggleDropdown();"
                  @submit="toggleDrawer(); toggleDropdown(); loadProducts()"
                />
              </div>
            </template>

            <template #toggle="{ toggleDrawer }">
              <RoleControlledAction
                v-slot="{ wrapper, restricted}"
                :user-role="roleName"
                :config-object="roleConfig.updateProducts"
              >
                <button
                  :class="{ 'custom-disabled': restricted}"
                  class="button button--block button--sm text-left"
                  @click="wrapper(() => toggleDrawer())"
                >
                  Update
                </button>
              </RoleControlledAction>
            </template>
          </TbDrawer>

          <button
            class="button button--block button--sm text-left flex gap-2 items-center"
            @click="toggleDropdown(); copyProductId(item)"
          >
            <TbCopyIcon class="w-4 h-4" />
            Copy Product ID
          </button>

          <!-- Delete -->
          <DeleteProduct
            :product="item"
            @archive="toggleDropdown(); loadProducts()"
          >
            <template #action="{ deleteHandler }">
              <RoleControlledAction
                v-slot="{ wrapper, restricted}"
                :user-role="roleName"
                :config-object="roleConfig.deleteProducts"
              >
                <button
                  :class="{ 'custom-disabled': restricted}"
                  class="button button--block text-error-900 button--sm text-left"
                  @click="wrapper(() => deleteHandler());"
                >
                  Delete
                </button>
              </RoleControlledAction>
            </template>
          </DeleteProduct>
        </div>
      </template>

      <template #row(state)="{ item }">
        <div>
          <span
            class="inline-block w-2 h-2 mr-1 rounded-full"
            :class="productStatusToColorMap[item.state]"
          />
          <span>
            {{ item.state === 'active' ? 'For Sale' : 'Not For Sale' }}
          </span>
        </div>
      </template>

      <template #row(activeCheckouts)="{ item }">
        <template v-if="item.activeCheckouts.length > 0">
          <div class="flex flex-row gap-2 items-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              class="w-4 h-4 fill-success-800"
            >
              <path
                fill-rule="evenodd"
                d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75
            9.75S2.25 17.385 2.25 12zm13.36-1.814a.75.75 0 10-1.22-.872l-3.236 4.53L9.53
            12.22a.75.75 0 00-1.06 1.06l2.25 2.25a.75.75 0 001.14-.094l3.75-5.25z"
                clip-rule="evenodd"
              />
            </svg>

            <span v-if="item.activeCheckouts.length > 0">
              Used in <strong class="font-semibold">{{ item.activeCheckouts.length }}
                checkout{{ item.activeCheckouts.length > 1 ? 's' : '' }}</strong>
            </span>
          </div>
        </template>

        <span v-else>
          <div class="flex flex-row gap-2 items-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              class="w-4 h-4 fill-gray-300"
            >
              <path
                fill-rule="evenodd"
                d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75
                9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zm-1.72 6.97a.75.75
                0 10-1.06 1.06L10.94 12l-1.72 1.72a.75.75 0 101.06 1.06L12
                13.06l1.72 1.72a.75.75 0 101.06-1.06L13.06 12l1.72-1.72a.75.75
                0 10-1.06-1.06L12 10.94l-1.72-1.72z"
                clip-rule="evenodd"
              />
            </svg>

            <span>
              Not used yet
            </span>
          </div>
        </span>
      </template>

      <template #row(priceInCents)="{ item }">
        <span class="currency">{{ item.currencyCode + currencyInCents(item.priceInCents) }}</span>
      </template>
    </Table>

    <TbNotification
      :show="copyNotification"
      title="Success 🎉"
      :message="copyNotificationText"
      card-class="text-white bg-success"
      @close="copyNotification = false"
    />
    <QuickStartGuideLink />
  </Page>
</template>

<script setup lang="ts">
import {
  ref, Ref, reactive, watch, onMounted, inject,
} from 'vue';
import { useRoute } from 'vue-router';
import { debounce } from 'lodash';
import {
  TbInput, TbPlusIcon, TbNotification, TbDrawer, TbSelect, TbCopyIcon,
} from '@/components/tasty_bistro';
import { useClipboard } from '@vueuse/core';
import { AppState } from '@/pages/app/api/get_app_state';
import { formatDate } from '@/filters/date';
import { currencyInCents } from '@/filters/currency';
import { productStatusToColorMap } from '@/helpers/category_color_mapper';
import QuickStartGuideLink from '@/components/quick_start_guide_link.vue';
import Table from '@/components/table.vue';
import RoleControlledAction from '@/components/role_controlled_action.vue';
import { PageState } from '@/types';
import Page from '../../components/page.vue';
import getProducts, { Product } from './api/get_products';
import Createproduct from './components/create.vue';
import UpdateProduct from './components/update.vue';
import DeleteProduct from './components/delete.vue';
import { roleConfig } from './role_configurations';

const route = useRoute();

const appState = inject<Ref<AppState>>('state') as Ref<AppState>;
const projectId = route.params.projectId as string;
const roleName = appState.value.projects[projectId].currentUserRoleName;

const products: Ref<Product[]> = ref([]);
const state = ref(PageState.loaded);
const filter = reactive({ search: '', state: 'all' });
const pageSize = 20;
const currentPage = ref(0);
const productCount = ref<number>(0);
const tableHeaders = [
  {
    title: 'Name',
    key: 'name',
  },
  {
    title: 'Price',
    key: 'priceInCents',
  },
  {
    title: 'Created At',
    key: 'createdAt',
    formatter: (val: string) => formatDate(val, 'MMM dd, yyyy'),
  },
  {
    title: 'Status',
    key: 'state',
  },
  {
    title: 'Checkouts',
    key: 'activeCheckouts',
  },
];

const stateOptions = [
  { name: 'For Sale', value: 'active' },
  { name: 'Not For Sale', value: 'inactive' },
];

const loadProducts = async () => {
  try {
    state.value = PageState.loading;
    const productsPaginated = await getProducts({
      projectId,
      filter: {
        name: filter.search,
        state: filter.state === 'all' ? undefined : filter.state,
        currentPage: currentPage.value,
        pageSize,
      },
    });
    products.value = productsPaginated.data;
    productCount.value = productsPaginated.count;
    state.value = PageState.loaded;
  } catch (error) {
    state.value = PageState.error;
  }
};

const copyNotification = ref(false);
const copyNotificationText = ref('');

const copyProductId = (product: Product) => {
  copyNotification.value = false;

  setTimeout(() => {
    const clipboard = useClipboard({ source: product.productId });
    clipboard.copy();
    copyNotificationText.value = `Product ID for ${product.name} has been successfully copied to your Clipboard`;
    copyNotification.value = true;
  }, 200);
};

const goToNextPage = async (page: number) => {
  currentPage.value = page;
  await loadProducts();
};

const goToPreviousPage = async (page: number) => {
  currentPage.value = page;
  await loadProducts();
};

onMounted(async () => {
  await loadProducts();
});

watch(() => [filter.state], async () => {
  currentPage.value = 0;
  await loadProducts();
});

watch(() => [filter.search], debounce(async () => {
  currentPage.value = 0;
  await loadProducts();
}, 500));
</script>

<style lang="scss" scoped>
  .custom-disabled {
    @apply cursor-not-allowed opacity-[0.35];
  }
</style>
